<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <b-button class="mr-2" variant="primary" @click="addsPret()"
            >Ajouter un prêt</b-button
          >

          <!-- bouton export start -->
          <b-button
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>


          <b-button
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>


          <!-- bouton export end -->
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-1 row">
            <!-- search start -->
            <div class="col-lg-3 col-md-3 mt-2 mt-lg-0">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Rechercher"
              >
              </b-form-input>
            </div>
            <div class="col-lg-3 col-md-3 mt-2 mt-lg-0">
              <b-form-select
                v-model="valueMembre"
                :options="membresList"
                value-field="name"
                text-field="name"
                @input="filterValue"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    style="color: #b4b7bd; text-align: center"
                    >-- Membres --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="col-lg-3 col-md-3 mt-2 mt-lg-0">
              <b-form-select
                v-model="valueCreancier"
                :options="creanciersList"
                value-field="name"
                text-field="name"
                @input="filterValue"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    style="color: #b4b7bd; text-align: center"
                    >-- Créancier --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="col-lg-3 col-md-3 mt-2 mt-lg-0">
              <b-input-group prepend="Crédit total">
                <b-form-input disabled v-model="montantTotal" />
              </b-input-group>
            </div>
            <!-- search end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="ExcelPretRecouvrement"
            id="my-table"
            responsive
            :fields="headers"
            :items="recouvrements"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            @filtered="onFiltered"
            show-empty
            class="border-bottom"
          >
            <template #cell(name)="data">
              <span>
                {{ data.item.name ? data.item.name : "----" }}
              </span>
            </template>

            <template #cell(phone)="data">
              <span>
                {{ data.item.phone ? data.item.phone : "----" }}
              </span>
            </template>
            <template #cell(creancier)="data">
              <span>
                {{ data.item.creancier ? data.item.creancier : "----" }}
              </span>
            </template>

            <template #cell(reste)="data">
              {{
                data.item.reste
                  ? $thousandSeparator(data.item.reste)
                  : "-------"
              }}
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div class="row">
                <div>
                  <b-button
                    :id="'button-1' + data.item.id"
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="addsRecouvrement(data.item)"
                  >
                    <feather-icon icon="RepeatIcon" />
                  </b-button>
                  <b-tooltip
                    placement="left"
                    :target="'button-1' + data.item.id"
                    title="Recouvrement"
                    variant="dark"
                  ></b-tooltip>
                </div>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                  </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal add prêt start  -->
        <b-modal
          id="modal-AddPret"
          size="lg"
          v-model="modalAddPret"
          title="Ajouter un prêt"
          hide-footer
          @hidden="addPretModalReset"
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="addPretForm"
            @submit.stop.prevent="handleSubmit"
          >
            <div class="row">
              <b-form-group
                class="col"
                label="Personnel/Partenaires"
                label-for="personnel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="personnel"
                  rules="required"
                >
                  <b-form-select
                    v-model="addPret.personnel"
                    :options="membresList"
                    value-field="id"
                    text-field="name"
                    @change="changePersonnel"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        style="color: #b4b7bd; text-align: center"
                        >-- personnels/partenaires --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : membresModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="col"
                label="Crédit déjà dû"
                label-for="credit_deja_du"
              >
                <validation-provider
                  #default="{ errors }"
                  name="credit_deja_du"
                  rules="required"
                >
                  <!-- <b-form-input
								id="credit_deja_du"
								type="text"
								v-model="addPret.debt"
								:state="errors.length > 0 ? false : null"
								placeholder=""
								@input="creditDuInputReset"
								disabled
							/> -->
                  <div class="bg-color rounded" style="padding: 8px">
                    <span class="font-weight-bold">{{
                      thousandSeparator(addPretdebt)
                    }}</span>
                  </div>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : creditDuModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                class="col"
                label="Montant du prêt"
                label-for="montant_pret"
              >
                <validation-provider
                  #default="{ errors }"
                  name="montant pret"
                  rules="required"
                >
                  <cleave
                    id="montant_pret"
                    v-model="addPret.montant_pret"
                    :state="errors.length > 0 ? false : null"
                    placeholder="0"
                    @input="montantPretInputReset"
                    class="form-control"
                    :raw="false"
                    :options="numberFormat"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : montantPretModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="col"
                label="Total Crédit"
                label-for="total_credit"
              >
                <validation-provider
                  #default="{ errors }"
                  name="total_credit"
                  rules="required"
                >
                  <div class="bg-color rounded" style="padding: 8px">
                    <span class="font-weight-bold">{{ total }}</span>
                  </div>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : totalCreditModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                class="col"
                label="Créanciers"
                label-for="creanciers"
              >
                <validation-provider
                  #default="{ errors }"
                  name="creanciers"
                  rules="required"
                >
                  <b-form-select
                    v-model="addPret.creanciers"
                    :options="creanciersList"
                    value-field="id"
                    text-field="name"
                    @change="changeCreancier(addPret)"
                    @input="creancierSelectReset"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        style="color: #b4b7bd; text-align: center"
                        >-- Sociétés/Associations --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : creancierModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col" label="Caisse" label-for="caisse">
                <validation-provider
                  #default="{ errors }"
                  name="caisse"
                  rules="required"
                >
                  <div class="bg-color rounded" style="padding: 8px">
                    <span class="font-weight-bold">{{
                      nameCaisse ? nameCaisse : "_"
                    }}</span>
                  </div>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : caissesModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                class="col"
                label="Motif du prêt"
                label-for="motif_pret"
              >
                <validation-provider
                  #default="{ errors }"
                  name="motif_pret"
                  rules="required"
                >
                  <b-form-textarea
                    id="motif_pret"
                    v-model="addPret.motif_pret"
                    placeholder="Motif"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    @input="motifPretInputReset"
                  >
                  </b-form-textarea>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : motifPretModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="col" label="Banque" label-for="bank">
                <validation-provider
                  #default="{ errors }"
                  name="bank"
                  rules="required"
                >
                  <b-form-select
                    id="bank"
                    v-model="addPret.compteCP"
                    :options="bankList"
                    @input="bankSelectReset"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : bankModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <b-form-group
              label="Modalités de remboursement"
              label-for="modalites_de_remboursement"
            >
              <validation-provider
                #default="{ errors }"
                name="modalites_de_remboursement"
                rules="required"
              >
                <b-form-textarea
                  id="modalites_de_remboursement"
                  v-model="addPret.modalites_remboursenement"
                  placeholder="Modalités"
                  :state="errors.length > 0 ? false : null"
                  @input="modalitesRemboursenementInputReset"
                >
                </b-form-textarea>
                <small class="text-danger">{{
                  errors[0] ? errors[0] : modalitesRemboursenementModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalAddPret = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonEdit"
                variant="primary"
                class="mt-2"
                @click="handleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal add prêt end-->

        <!-- modal add recouvrement start  -->
        <b-modal
          size="lg"
          id="modal-AddRecouvrement"
          v-model="modalAddRecouvrement"
          title="Ajouter un recouvrement"
          @hidden="AddRecouvrementModalReset"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="addRecouvrementForm"
            @submit.stop.prevent="handleSubmitRecouvrement"
          >
            <div class="row">
              <b-form-group
                class="col"
                label="Personnel Recouvrement"
                label-for="Personnel_recouvrement"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Personnel_recouvrement"
                  rules="required"
                >
                  <b-form-select
                    class="font-weight-bold"
                    v-model="addRecouvrement.id"
                    :options="membresList"
                    value-field="id"
                    text-field="name"
                    @input="membresSelectRecouvrementReset"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        style="color: #b4b7bd; text-align: center"
                        >-- personnels/partenaires --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : membresRecouvrementModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="col"
                label="Crédit Total"
                label-for="credit_total"
              >
                <validation-provider
                  #default="{ errors }"
                  name="credit_total"
                  rules="required"
                >
                  <div class="bg-color rounded" style="padding: 8px">
                    <span class="font-weight-bold">{{
                      thousandSeparator(addRecouvrement.debt)
                    }}</span>
                  </div>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : creditTotalModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <div class="row">
              <b-form-group
                class="col"
                label="Montant à recouvrer"
                label-for="montant_recouvrer"
              >
                <validation-provider
                  #default="{ errors }"
                  name="montant à recouvrer"
                  rules="required"
                >
                  <cleave
                    id="montant_recouvrer"
                    v-model="addRecouvrement.montant_recouvrement"
                    :state="errors.length > 0 ? false : null"
                    placeholder="0"
                    @input="montantRecouvrerInputReset"
                    :raw="false"
                    :options="numberFormat"
                    class="form-control"
                  />
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : montantRecouvrerModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="col"
                label="Reste à recouvrer"
                label-for="Reste_recouvrer"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reste_recouvrer"
                  rules="required"
                >
                  <div class="bg-color rounded" style="padding: 8px">
                    <span class="font-weight-bold">{{
                      thousandSeparator(reste)
                    }}</span>
                  </div>
                  <small class="text-danger">{{
                    errors[0] ? errors[0] : resteRecouvrerModalErrors
                  }}</small>
                </validation-provider>
              </b-form-group>
            </div>

            <b-form-group label="Banque" label-for="bank_recouvrement">
              <validation-provider
                #default="{ errors }"
                name="bank_recouvrement"
                rules="required"
              >
                <b-form-select
                  id="bank_recouvrement"
                  v-model="addRecouvrement.compteCP"
                  :options="bankList"
                  @input="bankRecouvrementSelectReset"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : bankRecouvrementModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalAddRecouvrement = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonRecouvrement"
                variant="primary"
                class="mt-2"
                @click="handleOkRecouvrement"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal add recouvrement end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="addsPret()">
          <b-icon-plus></b-icon-plus>
          AJOUTER PRÊTS
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconArrowClockwise,
  BIconCircleFill,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    Cleave,
    VueSweetalert2,
    VueGoodTable,
    BIconArrowClockwise,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    BFormTextarea,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },

  data() {
    return {
      etatButtonEdit: true,
      etatButtonRecouvrement: true,
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      dateFormatOptions: '{ day: "numeric", month: "long", year: "numeric" }',
      membresList: [
        {
          label: "PERSONNELS",
          options: [],
        },
        {
          label: "PARTENAIRES",
          options: [],
        },
      ],
      creanciersList: [
        {
          label: "SOCIETE",
          options: [],
        },
        {
          label: "ASSOCIATIONS",
          options: [],
        },
      ],
      nameCaisse: "",
      bankList: [],
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      modalAddPret: false,
      modalAddRecouvrement: false,
      perPage: 10,
      currentPage: 1,
      allRows: 0,
      filter: "",
      societe: {},
      pageOptions: [],
      headers: [
        {
          key: "personnel.name",
          label: "Personnel / Partenaire",
          sortable: true,
        },
        {
          key: "personnel.phone",
          label: "Téléphone",
          sortable: true,
          class: "text-center",
        },
        {
          key: "reste",
          label: "Crédit",
          sortable: true,
        },
        {
          key: "creancier",
          label: "Créancier",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
      recouvrements: [],
      ExcelPretRecouvrement: null,
      nameState: null,
      addPret: {
        personnel: "",
        debt: 0,
        montant_pret: 0,
        // total_credit: 0,
        motif_pret: "",
        creanciers: "",
        compteCP: "",
        debiteur: "",
        personnel_salaire: "",
        modalites_remboursenement: "",
        type: "",
      },
      valueSearch: null,
      valueMembre: null,
      valueCreancier: null,
      membresModalErrors: null,
      creditDuModalErrors: null,
      montantPretModalErrors: null,
      totalCreditModalErrors: null,
      motifPretModalErrors: null,
      creancierModalErrors: null,
      caissesModalErrors: null,
      bankModalErrors: null,
      modalitesRemboursenementModalErrors: null,
      addRecouvrement: {
        id: "",
        debt: 0,
        creanciers: "",
        montant_recouvrement: "",
        // Reste_recouvrer: 0,
        compteCP: "",
        name: "",
        phone: "",
        work: "",
        hiring_date: "",
      },
      membresRecouvrementModalErrors: null,
      creditTotalModalErrors: null,
      montantRecouvrerModalErrors: null,
      resteRecouvrerModalErrors: null,
      creancierRecouvrerModalErrors: null,
      bankRecouvrementModalErrors: null,
    };
  },

  computed: {
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    addPretdebt() {
      var membresList = [];
      this.membresListAll(this.membresList, membresList);
      let membre = membresList.find(({ id }) => id == this.addPret.personnel);
      return membre ? membre.debt : 0;
    },

    montantTotal() {
      let recouvrements = this.recouvrements;
      let total = 0;

      if (this.valueMembre) {
        recouvrements = recouvrements.filter(
          (rec) => rec.personnel.name == this.valueMembre
        );
      }

      if (this.valueCreancier) {
        recouvrements = recouvrements.filter(
          (rec) => rec.creancier == this.valueCreancier
        );
      }

      recouvrements.forEach((element) => {
        total += element.reste;
      });
      return this.$thousandSeparator(total);
    },

    total() {
      let num1 = this.addPretdebt;
      let num2 = this.addPret.montant_pret
        ? this.$thousandFormater(this.addPret.montant_pret)
        : 0;
      return this.thousandSeparator(num1 + num2);
    },

    reste() {
      let credit = this.addRecouvrement.debt;

      let montant_recouvrement = this.addRecouvrement.montant_recouvrement
        ? this.$thousandFormater(this.addRecouvrement.montant_recouvrement)
        : 0;
      let reste_c = parseInt(credit) - montant_recouvrement;

      if (reste_c < 0) {
        this.addRecouvrement.montant_recouvrement = 0;
        return credit;
      } else if (reste_c == 0) {
        return 0;
      } else {
        return reste_c;
      }
    },
  },

  created() {
    this.getMembres();
    this.getCreanciers();
    this.getBanks();
  },

  methods: {
    filterValue(val) {
      this.filter = " ";
    },


    
 onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
        totalRows()
      },

    filterFunction(row, DateFilter) {
      if (
        (this.valueMembre != null && this.valueMembre != row.personnel.name) ||
        (this.valueCreancier != null && this.valueCreancier != row.creancier)
      ) {
        return false;
      } else {
        if (
          this.valueSearch &&
          row.personnel.name
            .toLowerCase()
            .indexOf(this.valueSearch.toLowerCase()) == -1
        ) {
          return false;
        }
      }
      return true;
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    thousandSeparator(amount) {
      if (
        amount !== "" &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== "0" &&
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }
    },

    getMembres() {
      // get list personnels
      this.$http.get("/gestion-personnel/personnels").then((res) => {
        this.membresList[0].options = res.data.data.reverse();
      });

      // get list partenaires
      this.$http.get("/partenaires").then((res) => {
        this.membresList[1].options = res.data.data.reverse();
      });
    },

    async getCreanciers() {
      // get list societe
      await this.$http.get("/societes").then((res) => {
        this.societe = res.data.data[0];
        this.creanciersList[0].options = res.data.data.reverse();
        this.creanciersList[0].options[0].id = 0;
      });

      // get list association
      this.$http.get("/associations").then((res) => {
        this.creanciersList[1].options = res.data.data.reverse();
      });

      this.getRecouvrements();
    },

    getBanks() {
      this.$http.get("/caisse-principale-resources").then((res) => {
        res.data.data.reverse().forEach((element) => {
          var obj = {
            value: element.id,
            text: element.libelle,
          };

          this.bankList.push(obj);
        });
      });
    },

    async getRecouvrements() {
      await this.$http.get("/gestion-personnel/recouvrements").then((res) => {
        if (res.status) {
          this.hiddenLoader = true;
        }
        res.data.data.forEach((element) => {
          element.creancier = element.creancier
            ? element.creancier
            : this.societe.name;
        });
        this.recouvrements = res.data.data.reverse();

        if (this.recouvrements.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }


        this.allRows = this.recouvrements.length

      });
    },

    membresListAll(list, array) {
      list[0].options.forEach((element) => {
        array.push(element);
      });

      list[1].options.forEach((element) => {
        array.push(element);
      });
    },

    changeCreancier(element) {
      if (element.creanciers == 0 || element.creanciers != 0) {
        var creanciersList = [];

        this.membresListAll(this.creanciersList, creanciersList);

        let creancier = creanciersList.find(
          ({ id }) => id == element.creanciers
        );
        if (element.id) {
          if (creancier.hascaisse == 0) {
            this.creancierRecouvrerModalErrors =
              "Ce créancier n'a pas de caisse !";
          }
        } else {
          if (creancier.hascaisse == 1) {
            this.nameCaisse = creancier.name;
          } else if (creancier.hascaisse == 0) {
            if (this.nameCaisse) {
              this.nameCaisse = "";
            }
            this.creancierModalErrors = "Ce créancier n'a pas de caisse !";
          } else {
            this.nameCaisse = creancier.name;
          }
        }
      }
    },

    changePersonnel() {
      if (this.addPret.personnel) {
        var membresList = [];

        this.membresListAll(this.membresList, membresList);

        let membre = membresList.find(({ id }) => id == this.addPret.personnel);

        this.addPret.personnel_salaire = membre.salary;
        this.addPret.debt = membre.debt ? membre.debt : 0;
      }
    },

    // add pret start
    addsPret() {
      this.modalAddPret = true;
    },

    checkFormValidity() {
      const valid = this.$refs.addPretForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      if (
        this.addPret.personnel &&
        this.addPret.montant_pret &&
        (this.addPret.creanciers == 0 || this.addPret.creanciers) &&
        this.addPret.motif_pret &&
        this.addPret.compteCP &&
        this.addPret.modalites_remboursenement &&
        !this.creancierModalErrors
      ) {
        this.addPret.montant_pret = this.addPret.montant_pret
          ? this.$thousandFormater(this.addPret.montant_pret)
          : 0;

        this.addPret.type = "pret";

        var membresList = [];

        this.membresListAll(this.membresList, membresList);

        this.addPret.debiteur = membresList.find(
          ({ id }) => id == this.addPret.personnel
        ).name;

        this.etatButtonEdit = false;

        this.$http
          .post("/gestion-personnel/historiques", this.addPret)
          .then((result) => {
            if (result.data.success) {
              this.etatButtonEdit = true;
              this.modalAddPret = false;
              this.showToast("Prêt enregistré avec succès", "success");

              let toPrint = this.addPret;
              toPrint.creancier = result.data.data.creancier;
              let routeData = this.$router.resolve({
                name: "personnel-print-fiche-de-pret",
                query: toPrint,
              });
              window.open(routeData.href, "_blank");

              this.addPret = JSON.parse(
                JSON.stringify({ type: Object, default: () => {} })
              );

              this.getRecouvrements();
              this.getMembres();
            } else {
              this.etatButtonEdit = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonEdit = true;

            var err = e.response.data.errors;

            if (err) {
              if (err.personnel) {
                this.membresModalErrors = err.personnel[0];
              }

              if (err.montant_pret) {
                this.montantPretModalErrors = err.montant_pret[0];
              }

              if (err.motif_pret) {
                this.motifPretModalErrors = err.motif_pret[0];
              }

              if (err.compteCP) {
                this.bankModalErrors = err.compteCP[0];
              }

              if (err.modalites_remboursenement) {
                this.modalitesRemboursenementModalErrors =
                  err.modalites_remboursenement[0];
              }
            } // else if (e.response.status == 404) {
            // 	this.addPret = JSON.parse(
            // 		JSON.stringify({ type: Object, default: () => {} })
            // 	);
            // 	this.modalAddPret = false;
            // 	this.showToast("Prêt effectué avec succès", "success");
            // 	this.getRecouvrements();
            // }
          });
      } else {
        if (!this.addPret.personnel) {
          this.membresModalErrors = "Ce champ est requis !";
        } else {
          this.membresModalErrors = "";
        }

        if (!this.addPret.montant_pret || this.addPret.montant_pret == 0) {
          this.montantPretModalErrors = "Ce champ est requis !";
        } else {
          this.montantPretModalErrors = "";
        }

        if (!this.addPret.creanciers) {
          this.creancierModalErrors = "Ce champ est requis !";
        } else {
          this.creancierModalErrors = "";
        }

        if (!this.addPret.motif_pret) {
          this.motifPretModalErrors = "Ce champ est requis !";
        } else {
          this.motifPretModalErrors = "";
        }

        if (!this.addPret.compteCP) {
          this.bankModalErrors = "Ce champ est requis !";
        } else {
          this.bankModalErrors = "";
        }

        if (!this.addPret.modalites_remboursenement) {
          this.modalitesRemboursenementModalErrors = "Ce champ est requis !";
        } else {
          this.modalitesRemboursenementModalErrors = "";
        }
      }
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmit();
    },

    addPretModalReset() {
      this.addPret.personnel = "";
      this.addPret.montant_pret = "";
      this.addPret.debt = "";
      this.addPret.motif_pret = "";
      this.addPret.creanciers = "";
      this.nameCaisse = "";
      this.addPret.compteCP = "";
      this.addPret.modalites_remboursenement = "";
      this.nameState = null;
      this.InputModalAllReset();
    },

    InputModalAllReset() {
      this.membresSelectReset();
      // this.creditDuInputReset()
      this.montantPretInputReset();
      // this.totalCreditInputReset()
      this.motifPretInputReset();
      this.bankSelectReset();
      this.modalitesRemboursenementInputReset();
      this.creancierSelectReset();
    },

    membresSelectReset() {
      if (this.membresModalErrors) {
        this.membresModalErrors = null;
      }
    },

    // creditDuInputReset() {
    // 	if (this.creditDuModalErrors) {
    // 		this.creditDuModalErrors = null;
    // 	}
    // },

    montantPretInputReset() {
      if (this.montantPretModalErrors) {
        this.montantPretModalErrors = null;
      }
    },

    // totalCreditInputReset() {
    // 	if (this.totalCreditModalErrors) {
    // 		this.totalCreditModalErrors = null;
    // 	}
    // },

    motifPretInputReset() {
      if (this.motifPretModalErrors) {
        this.motifPretModalErrors = null;
      }
    },

    creancierSelectReset() {
      if (this.creancierModalErrors) {
        this.creancierModalErrors = null;
      }
    },

    bankSelectReset() {
      if (this.bankModalErrors) {
        this.bankModalErrors = null;
      }
    },

    modalitesRemboursenementInputReset() {
      if (this.modalitesRemboursenementModalErrors) {
        this.modalitesRemboursenementModalErrors = null;
      }
    },
    // add pret end

    // add recouvrement start
    addsRecouvrement(item) {
      this.addRecouvrement.id = item.personnel.id;
      this.addRecouvrement.pret = item.id;
      this.addRecouvrement.debt = item.reste;
      this.addRecouvrement.creanciers = item.creancier;

      this.addRecouvrement.name = item.personnel.name;
      this.addRecouvrement.phone = item.personnel.phone;
      this.addRecouvrement.work = item.personnel.work;
      this.addRecouvrement.hiring_date = item.personnel.hiring_date;

      this.modalAddRecouvrement = true;
    },

    checkFormRecouvrementValidity() {
      const valid = this.$refs.addRecouvrementForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    handleSubmitRecouvrement() {
      if (!this.checkFormRecouvrementValidity()) {
        return;
      }
      if (
        this.addRecouvrement.compteCP &&
        !this.creancierRecouvrerModalErrors
      ) {
        this.addRecouvrement.montant_recouvrement = this.addRecouvrement
          .montant_recouvrement
          ? this.$thousandFormater(this.addRecouvrement.montant_recouvrement)
          : 0;

        this.etatButtonRecouvrement = false;

        this.$http
          .post("/gestion-personnel/recouvrements", this.addRecouvrement)
          .then((result) => {
            if (result.data.success) {
              this.etatButtonRecouvrement = true;
              this.modalAddRecouvrement = false;
              this.showToast("Recouvrement effectué avec succès", "success");

              // Removing not important values from the object
              let toPrint = this.addRecouvrement;
              toPrint.montant_recouvrement = this.$thousandFormater(
                toPrint.montant_recouvrement
              );
              delete toPrint.conges;
              delete toPrint.current_prime;
              delete toPrint.current_prime;
              delete toPrint.salary;
              let routeData = this.$router.resolve({
                name: "personnel-print-fiche-de-remboursement",
                query: toPrint,
              });
              window.open(routeData.href, "_blank");

              this.getRecouvrements();
              this.getMembres();
            } else {
              this.etatButtonRecouvrement = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonRecouvrement = true;
            var err = e.response.data.errors;

            if (err) {
              if (err.personnel) {
                this.membresRecouvrementModalErrors = err.personnel[0];
              }

              if (err.montant_recouvrement) {
                this.montantRecouvrerModalErrors = err.montant_recouvrement[0];
              }

              if (err.compteCP) {
                this.bankRecouvrementModalErrors = err.compteCP[0];
              }
            } // else if (e.response.status == 404) {
            // 	this.addRecouvrement = JSON.parse(
            // 		JSON.stringify({ type: Object, default: () => {} })
            // 	);
            // 	this.modalAddRecouvrement = false;
            // 	this.showToast("Recouvrement effectué avec succès", "success");
            // 	this.getRecouvrements();
            // }
          });
      } else {
        if (!this.addRecouvrement.creanciers) {
          this.creancierRecouvrerModalErrors = "Ce champ est requis !";
        } else {
          this.creancierRecouvrerModalErrors = "";
        }

        if (
          !this.addRecouvrement.montant_recouvrement ||
          this.addRecouvrement.montant_recouvrement == 0
        ) {
          this.montantRecouvrerModalErrors = "Ce champ est requis !";
        } else {
          this.montantRecouvrerModalErrors = "";
        }

        if (!this.addRecouvrement.compteCP) {
          this.bankRecouvrementModalErrors = "Ce champ est requis !";
        } else {
          this.bankRecouvrementModalErrors = "";
        }
      }

      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    handleOkRecouvrement(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.handleSubmitRecouvrement();
    },

    AddRecouvrementModalReset() {
      this.addRecouvrement.personnel = "";
      this.addRecouvrement.debt = "";
      this.addRecouvrement.montant_recouvrement = "";
      this.addRecouvrement.creanciers = "";
      this.addRecouvrement.compteCP = "";
      this.addRecouvrement.name = "";
      this.addRecouvrement.phone = "";
      this.addRecouvrement.work = "";
      this.addRecouvrement.hiring_date = "";
      this.nameState = null;
      this.InputRecouvrementModalAllReset();
    },

    InputRecouvrementModalAllReset() {
      this.membresSelectRecouvrementReset();
      this.montantRecouvrerInputReset();
      this.creancierRecouvrerSelectReset();
      this.bankRecouvrementSelectReset();
    },

    membresSelectRecouvrementReset() {
      if (this.membresRecouvrementModalErrors) {
        this.membresRecouvrementModalErrors = null;
      }
    },

    montantRecouvrerInputReset() {
      if (this.montantRecouvrerModalErrors) {
        this.montantRecouvrerModalErrors = null;
      }
    },

    creancierRecouvrerSelectReset() {
      if (this.creancierRecouvrerModalErrors) {
        this.creancierRecouvrerModalErrors = null;
      }
    },

    bankRecouvrementSelectReset() {
      if (this.bankRecouvrementModalErrors) {
        this.bankRecouvrementModalErrors = null;
      }
    },
    // add recouvrement end

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.recouvrements.forEach((element) => {
        obj = {
          "PERSONNEL / PARTENAIRE": element.personnel
            ? element.personnel.name
            : "",
          TÉLÉPHONE: element.personnel ? element.personnel.phone : "",
          CRÉDIT: element.personnel ? element.personnel.debt : "",
          CREANCIER: element.creancier,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Prêt-Recou",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelPretRecouvrement.forEach((element) => {
        obj = {
          "PERSONNEL / PARTENAIRE": element.personnel
            ? element.personnel.name
            : "",
          TÉLÉPHONE: element.personnel ? element.personnel.phone : "",
          CRÉDIT: element.personnel ? element.personnel.debt : "",
          CREANCIER: element.creancier,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Prêt-Recou",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


  },
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.bg-color {
  background-color: #4b4b4b40;
}

// .bgcolor-button button{
// 	background-color: #27295c !important;
// }
</style>
